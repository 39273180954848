import React, { useState, useEffect, useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { array, object } from 'prop-types';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import style from './GlobalTemp.module.scss';
import useGetImage from './useGetImage';
import { useBreakpoints } from '@hooks';
import LangContext from '@contexts';
import { langPath } from '@helpers';
import Image from '../../../../components/Image/Image';

const GlobalTemp = ({ primary, items }) => {
  const [buildKey, setBuildKey] = useState();
  const { width } = useBreakpoints();
  const { arrow } = useGetImage();

  const currentLang = useContext(LangContext);
  const [visibleItems, setVisibleItems] = useState(24);
  const { title, description } = primary;

  useEffect(() => {
    setBuildKey(+new Date());
  }, [width]);

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 16,
    breakpoints: {
      768: {
        spaceBetween: 32,
      },
      1024: {
        allowTouchMove: false,
        spaceBetween: 0,
      },
    },
  };

  const showLessItems = () => {
    setVisibleItems(24);
  };

  const totalItems = items?.length;
  const allItemsVisible = visibleItems >= totalItems;

  const showMoreItems = () => {
    setVisibleItems(totalItems);
  };

  return (
    <div className={style.solutions}>
      <div className={style.container}>
        <div className={style.title}>
          <RichText render={title.richText} />
        </div>
        <div className={style.descr}>
          <RichText render={description.richText} />
        </div>
        <Swiper {...params} key={buildKey}>
          {items
            ?.slice(0, visibleItems)
            ?.map(
              (
                { icon_image, title: itemTitle, image: itemImg, lawlink },
                index
              ) => {
                const link = `${langPath(currentLang)}/${RichText.asText(
                  lawlink?.richText
                )}`;

                return (
                  <div className={style.slide} key={index}>
                    <Link to={link} className={style.item}>
                      <Image image={icon_image} className={style.icon} />
                      <div className={style.data}>
                        <Image image={itemImg} className={style.image} />
                        <div className={style.maindata}>
                          <RichText render={itemTitle.richText} />
                        </div>
                      </div>
                      <img
                        className={style.arrow}
                        src={arrow.publicURL}
                        alt="arrow icon"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                );
              }
            )}
        </Swiper>

        {allItemsVisible ? (
          visibleItems > 24 && (
            <button className={style.viewButton} onClick={showLessItems}>
              See Less
            </button>
          )
        ) : (
          <button className={style.viewButton} onClick={showMoreItems}>
            See More
          </button>
        )}
      </div>
    </div>
  );
};

GlobalTemp.propTypes = {
  primary: object,
  items: array,
};

export default GlobalTemp;
